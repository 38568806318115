import * as React from 'react';
export const SmallCarryOnIcon = (React.createElement("svg", { width: "80", height: "102", viewBox: "0 0 80 102", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("circle", { cx: "40.0001", cy: "46.6959", r: "4.74327", stroke: "#404C56", strokeWidth: "2" }),
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M33.6767 40.9526H23.0512L16.5183 63.6262H63.1042L56.8824 40.9526H46.3231C46.231 44.3657 43.4352 47.1043 39.9999 47.1043C36.5645 47.1043 33.7688 44.3657 33.6767 40.9526Z", fill: "#647094" }),
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M33.9965 40.9526H23.371L19.8381 63.6262H60.424L57.2022 40.9526H46.6429C46.5508 44.3657 43.7551 47.1043 40.3197 47.1043C36.8844 47.1043 34.0886 44.3657 33.9965 40.9526Z", fill: "url(#paint0_linear_190_18)" }),
    React.createElement("path", { d: "M16 65.3037C16 58.6763 21.3726 53.3037 28 53.3037H52C58.6274 53.3037 64 58.6763 64 65.3037V92.0001C64 95.3138 61.3137 98.0001 58 98.0001H22C18.6863 98.0001 16 95.3138 16 92.0001V65.3037Z", fill: "url(#paint1_linear_190_18)" }),
    React.createElement("path", { opacity: "0.5", d: "M16 70.1958C16 63.5684 21.3726 58.1958 28 58.1958H52C58.6274 58.1958 64 63.5684 64 70.1958V92.0004C64 95.3142 61.3137 98.0004 58 98.0004H22C18.6863 98.0004 16 95.3141 16 92.0004V70.1958Z", fill: "#191940" }),
    React.createElement("path", { d: "M16 72.5957C16 65.9683 21.3726 60.5957 28 60.5957H52C58.6274 60.5957 64 65.9683 64 72.5957V92.0003C64 95.314 61.3137 98.0003 58 98.0003H22C18.6863 98.0003 16 95.314 16 92.0003V72.5957Z", fill: "url(#paint2_linear_190_18)" }),
    React.createElement("rect", { opacity: "0.5", x: "21.8628", y: "52", width: "36.2744", height: "16.5217", rx: "5", fill: "#191940" }),
    React.createElement("rect", { x: "21.8628", y: "51", width: "36.2744", height: "16.5217", rx: "5", fill: "url(#paint3_linear_190_18)" }),
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M37.9717 50.4849H41.9951C42.5429 50.4849 42.9888 50.9225 42.9938 51.4703C43.0769 60.5953 42.9837 77.8776 42.9837 77.8776C42.9837 77.8776 40.8795 79.8816 39.9777 80.8836L36.9717 77.8776L36.9717 51.4849C36.9717 50.9326 37.4194 50.4849 37.9717 50.4849ZM40 72.5571C40.5523 72.5571 41 72.1094 41 71.5571C41 71.0048 40.5523 70.5571 40 70.5571C39.4477 70.5571 39 71.0048 39 71.5571C39 72.1094 39.4477 72.5571 40 72.5571ZM40 74.8452C40.5523 74.8452 41 74.3975 41 73.8452C41 73.2929 40.5523 72.8452 40 72.8452C39.4477 72.8452 39 73.2929 39 73.8452C39 74.3975 39.4477 74.8452 40 74.8452ZM41 76.1333C41 76.6856 40.5523 77.1333 40 77.1333C39.4477 77.1333 39 76.6856 39 76.1333C39 75.581 39.4477 75.1333 40 75.1333C40.5523 75.1333 41 75.581 41 76.1333Z", fill: "#404C56" }),
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M36.3885 69.9424C36.1123 69.9424 35.8885 70.1662 35.8885 70.4424V73.8424C35.8885 74.1185 36.1123 74.3424 36.3885 74.3424H37.1094V75.3424H36.3885C35.5601 75.3424 34.8885 74.6708 34.8885 73.8424V70.4424C34.8885 69.614 35.5601 68.9424 36.3885 68.9424H43.6113C44.4398 68.9424 45.1113 69.614 45.1113 70.4424V73.8424C45.1113 74.6708 44.4398 75.3424 43.6113 75.3424H43.113V74.3424H43.6113C43.8875 74.3424 44.1113 74.1185 44.1113 73.8424V70.4424C44.1113 70.1662 43.8875 69.9424 43.6113 69.9424H40.5858V72.3182H39.5858V69.9424H36.3885Z", fill: "white" }),
    React.createElement("defs", null,
        React.createElement("linearGradient", { id: "paint0_linear_190_18", x1: "40.1311", y1: "40.9526", x2: "40.1311", y2: "63.6262", gradientUnits: "userSpaceOnUse" },
            React.createElement("stop", { stopColor: "#87A3D2" }),
            React.createElement("stop", { offset: "1", stopColor: "#687EAE" })),
        React.createElement("linearGradient", { id: "paint1_linear_190_18", x1: "43.7266", y1: "97.4238", x2: "43.7266", y2: "53.3037", gradientUnits: "userSpaceOnUse" },
            React.createElement("stop", { stopColor: "#738AC5" }),
            React.createElement("stop", { offset: "1", stopColor: "#85B0E1" })),
        React.createElement("linearGradient", { id: "paint2_linear_190_18", x1: "40", y1: "60.5957", x2: "40", y2: "98.0003", gradientUnits: "userSpaceOnUse" },
            React.createElement("stop", { stopColor: "#738AC5" }),
            React.createElement("stop", { offset: "1", stopColor: "#85B0E1" })),
        React.createElement("linearGradient", { id: "paint3_linear_190_18", x1: "40", y1: "67.5215", x2: "40", y2: "51", gradientUnits: "userSpaceOnUse" },
            React.createElement("stop", { stopColor: "#7289C4" }),
            React.createElement("stop", { offset: "1", stopColor: "#85B0E1" })))));
export const LargeCarryOnIcon = (React.createElement("svg", { width: "80", height: "102", viewBox: "0 0 80 102", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("g", { style: {
            mixBlendMode: 'multiply'
        } },
        React.createElement("circle", { cx: "40", cy: "46.6964", r: "4.74327", stroke: "#404C56", strokeWidth: "2" }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M33.6767 40.9531H23.0512L16.5183 63.6267H63.1042L56.8824 40.9531H46.3231C46.231 44.3662 43.4352 47.1047 39.9999 47.1047C36.5645 47.1047 33.7688 44.3662 33.6767 40.9531Z", fill: "#647094" }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M33.9965 40.9531H23.371L19.8381 63.6267H60.424L57.2022 40.9531H46.6429C46.5508 44.3662 43.7551 47.1047 40.3197 47.1047C36.8844 47.1047 34.0886 44.3662 33.9965 40.9531Z", fill: "url(#paint0_linear_13635_49293)" }),
        React.createElement("path", { d: "M16 65.3037C16 58.6763 21.3726 53.3037 28 53.3037H52C58.6274 53.3037 64 58.6763 64 65.3037V92.0001C64 95.3138 61.3137 98.0001 58 98.0001H22C18.6863 98.0001 16 95.3138 16 92.0001V65.3037Z", fill: "url(#paint1_linear_13635_49293)" }),
        React.createElement("path", { opacity: "0.5", d: "M16 70.1953C16 63.5679 21.3726 58.1953 28 58.1953H52C58.6274 58.1953 64 63.5679 64 70.1953V92C64 95.3137 61.3137 98 58 98H22C18.6863 98 16 95.3137 16 92V70.1953Z", fill: "#191940" }),
        React.createElement("path", { d: "M16 72.5957C16 65.9683 21.3726 60.5957 28 60.5957H52C58.6274 60.5957 64 65.9683 64 72.5957V92.0003C64 95.314 61.3137 98.0003 58 98.0003H22C18.6863 98.0003 16 95.314 16 92.0003V72.5957Z", fill: "url(#paint2_linear_13635_49293)" }),
        React.createElement("rect", { opacity: "0.5", x: "21.8628", y: "52", width: "36.2744", height: "16.5217", rx: "5", fill: "#191940" }),
        React.createElement("rect", { x: "21.8628", y: "51", width: "36.2744", height: "16.5217", rx: "5", fill: "url(#paint3_linear_13635_49293)" }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M37.9717 50.4844H41.9951C42.5429 50.4844 42.9888 50.922 42.9938 51.4698C43.0769 60.5948 42.9837 77.8771 42.9837 77.8771C42.9837 77.8771 40.8795 79.8811 39.9777 80.8831L36.9717 77.8771L36.9717 51.4844C36.9717 50.9321 37.4194 50.4844 37.9717 50.4844ZM40 72.5566C40.5523 72.5566 41 72.1089 41 71.5566C41 71.0044 40.5523 70.5566 40 70.5566C39.4477 70.5566 39 71.0044 39 71.5566C39 72.1089 39.4477 72.5566 40 72.5566ZM40 74.8447C40.5523 74.8447 41 74.397 41 73.8447C41 73.2924 40.5523 72.8447 40 72.8447C39.4477 72.8447 39 73.2924 39 73.8447C39 74.397 39.4477 74.8447 40 74.8447ZM41 76.1328C41 76.6851 40.5523 77.1328 40 77.1328C39.4477 77.1328 39 76.6851 39 76.1328C39 75.5805 39.4477 75.1328 40 75.1328C40.5523 75.1328 41 75.5805 41 76.1328Z", fill: "#404C56" }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M36.3885 69.9424C36.1123 69.9424 35.8885 70.1662 35.8885 70.4424V73.8424C35.8885 74.1185 36.1123 74.3424 36.3885 74.3424H37.1094V75.3424H36.3885C35.5601 75.3424 34.8885 74.6708 34.8885 73.8424V70.4424C34.8885 69.614 35.5601 68.9424 36.3885 68.9424H43.6113C44.4398 68.9424 45.1113 69.614 45.1113 70.4424V73.8424C45.1113 74.6708 44.4398 75.3424 43.6113 75.3424H43.113V74.3424H43.6113C43.8875 74.3424 44.1113 74.1185 44.1113 73.8424V70.4424C44.1113 70.1662 43.8875 69.9424 43.6113 69.9424H40.5858V72.3182H39.5858V69.9424H36.3885Z", fill: "white" })),
    React.createElement("defs", null,
        React.createElement("linearGradient", { id: "paint0_linear_13635_49293", x1: "40.1311", y1: "40.9531", x2: "40.1311", y2: "63.6267", gradientUnits: "userSpaceOnUse" },
            React.createElement("stop", { stopColor: "#87A3D2" }),
            React.createElement("stop", { offset: "1", stopColor: "#687EAE" })),
        React.createElement("linearGradient", { id: "paint1_linear_13635_49293", x1: "43.7266", y1: "97.4238", x2: "43.7266", y2: "53.3037", gradientUnits: "userSpaceOnUse" },
            React.createElement("stop", { stopColor: "#738AC5" }),
            React.createElement("stop", { offset: "1", stopColor: "#85B0E1" })),
        React.createElement("linearGradient", { id: "paint2_linear_13635_49293", x1: "40", y1: "60.5957", x2: "40", y2: "98.0003", gradientUnits: "userSpaceOnUse" },
            React.createElement("stop", { stopColor: "#738AC5" }),
            React.createElement("stop", { offset: "1", stopColor: "#85B0E1" })),
        React.createElement("linearGradient", { id: "paint3_linear_13635_49293", x1: "40", y1: "67.5215", x2: "40", y2: "51", gradientUnits: "userSpaceOnUse" },
            React.createElement("stop", { stopColor: "#7289C4" }),
            React.createElement("stop", { offset: "1", stopColor: "#85B0E1" })))));
export const SmallBaggageIcon = (React.createElement("svg", { width: "70", height: "120", viewBox: "0 0 70 120", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { opacity: "0.2", fillRule: "evenodd", clipRule: "evenodd", d: "M12.9534 29.5386C9.86908 29.5386 7.36876 32.0389 7.36876 35.1232V39.5373C7.36876 42.6216 9.86908 45.1219 12.9534 45.1219H56.7836C59.8679 45.1219 62.3682 42.6216 62.3682 39.5373V35.1232C62.3682 32.0389 59.8679 29.5386 56.7836 29.5386H12.9534ZM12.9528 48.7885C9.86848 48.7885 7.36816 51.2888 7.36816 54.3731V100.954C7.36816 104.038 9.86848 106.539 12.9528 106.539H56.783C59.8673 106.539 62.3676 104.038 62.3676 100.954V54.3731C62.3676 51.2888 59.8673 48.7885 56.783 48.7885H12.9528Z", fill: "currentColor" }),
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M48.4846 22.2393V4.90578C48.4846 2.19639 46.2922 0 43.5877 0H26.715C24.0105 0 21.8181 2.19639 21.8181 4.90578V22.2393H8.56957C3.83673 22.2393 0 26.0993 0 30.8608V105.355C0 110.116 3.83673 113.976 8.56957 113.976H61.4302C66.163 113.976 69.9997 110.116 69.9997 105.355V30.8608C69.9997 26.0993 66.163 22.2393 61.4302 22.2393H48.4846ZM44.8483 22.2393V7.35891H25.4544V22.2393H44.8483ZM54.847 9.71442C54.4663 9.71442 54.1078 9.89432 53.8788 10.2002L51.1554 13.838C50.8302 14.2724 50.8302 14.8709 51.1554 15.3053L53.8788 18.9431C54.1078 19.249 54.4664 19.4289 54.847 19.4289H68.7879C69.4573 19.4289 70 18.883 70 18.2095V10.9339C70 10.2604 69.4573 9.71442 68.7879 9.71442H54.847ZM62.727 12.1434H61.5149V17.0007H62.727V12.1434ZM60.6059 12.1435H58.1816V17.0007H60.6059V12.1435ZM63.939 12.1434H65.1511V17.0007H63.939V12.1434ZM8.56957 24.6677H61.4302C64.8299 24.6677 67.586 27.4405 67.586 30.8608V105.355C67.586 108.775 64.8299 111.548 61.4302 111.548H8.56957C5.16983 111.548 2.41378 108.775 2.41378 105.355V30.8608C2.41378 27.4405 5.16982 24.6677 8.56957 24.6677ZM12.8945 115.829V115.83C12.8945 118.133 14.7502 120 17.0393 120C19.3283 120 21.184 118.133 21.184 115.83V115.829H12.8945ZM48.8159 115.83V115.829H57.1054V115.83C57.1054 118.133 55.2497 120 52.9606 120C50.6716 120 48.8159 118.133 48.8159 115.83Z", fill: "currentColor" })));
export const MiddleBaggageIcon = (React.createElement("svg", { width: "56", height: "91", viewBox: "0 0 56 91", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("g", { clipPath: "url(#clip0_14250_37998)" },
        React.createElement("path", { d: "M32 1.45605H24C20.7867 1.45605 18.1818 4.06355 18.1818 7.28006V26.9361C18.1818 30.1526 20.7867 32.7601 24 32.7601H32C35.2133 32.7601 37.8182 30.1526 37.8182 26.9361V7.28006C37.8182 4.06355 35.2133 1.45605 32 1.45605Z", stroke: "#404C56", strokeWidth: "4" }),
        React.createElement("path", { d: "M13.0909 88.0879H18.1818V89.5439C18.1818 90.3483 17.5306 90.9999 16.7273 90.9999H14.5455C13.7421 90.9999 13.0909 90.3483 13.0909 89.5439V88.0879Z", fill: "#404C56" }),
        React.createElement("path", { d: "M37.8182 88.0879H42.9091V89.5439C42.9091 90.3483 42.2579 90.9999 41.4545 90.9999H39.2727C38.4694 90.9999 37.8182 90.3483 37.8182 89.5439V88.0879Z", fill: "#404C56" }),
        React.createElement("path", { d: "M0 40.0402C0 27.9783 9.76836 18.2002 21.8182 18.2002H34.1818C46.2316 18.2002 56 27.9783 56 40.0402V79.3522C56 84.1767 52.0927 88.0882 47.2727 88.0882H8.72727C3.90733 88.0882 0 84.1767 0 79.3522V40.0402Z", fill: "#DF92BC" }),
        React.createElement("path", { d: "M0 44.4084C0 32.3465 9.76836 22.5684 21.8182 22.5684H34.1818C46.2316 22.5684 56 32.3465 56 44.4084V79.3524C56 84.1768 52.0927 88.0884 47.2727 88.0884H8.72727C3.90733 88.0884 0 84.1768 0 79.3524V44.4084Z", fill: "#6A5583" }),
        React.createElement("path", { d: "M0 47.3205C0 35.2586 9.76836 25.4805 21.8182 25.4805H34.1818C46.2316 25.4805 56 35.2586 56 47.3205V79.3525C56 84.1769 52.0927 88.0885 47.2727 88.0885H8.72727C3.90733 88.0885 0 84.1769 0 79.3525V47.3205Z", fill: "url(#paint0_linear_14250_37998)" }),
        React.createElement("path", { d: "M44.3636 6.55176H33.4545V10.9198H44.3636V6.55176Z", fill: "#404C56" }),
        React.createElement("path", { d: "M38.1136 7.56738H43.7188V8.0333H38.1136V7.56738Z", fill: "white" }),
        React.createElement("path", { d: "M38.1136 8.50293H43.7188V8.96885H38.1136V8.50293Z", fill: "white" }),
        React.createElement("path", { d: "M38.1136 9.43848H43.7188V9.9044H38.1136V9.43848Z", fill: "white" }),
        React.createElement("path", { d: "M19.6364 37.8564C19.6364 37.0523 18.9851 36.4004 18.1818 36.4004C17.3785 36.4004 16.7273 37.0523 16.7273 37.8564V56.7844C16.7273 57.5885 17.3785 58.2404 18.1818 58.2404C18.9851 58.2404 19.6364 57.5885 19.6364 56.7844V37.8564Z", fill: "white", fillOpacity: "0.2" }),
        React.createElement("path", { d: "M26.1818 37.8564C26.1818 37.0523 25.5306 36.4004 24.7273 36.4004C23.924 36.4004 23.2727 37.0523 23.2727 37.8564V56.7844C23.2727 57.5885 23.924 58.2404 24.7273 58.2404C25.5306 58.2404 26.1818 57.5885 26.1818 56.7844V37.8564Z", fill: "white", fillOpacity: "0.2" }),
        React.createElement("path", { d: "M32.7273 37.8564C32.7273 37.0523 32.076 36.4004 31.2727 36.4004C30.4694 36.4004 29.8182 37.0523 29.8182 37.8564V56.7844C29.8182 57.5885 30.4694 58.2404 31.2727 58.2404C32.076 58.2404 32.7273 57.5885 32.7273 56.7844V37.8564Z", fill: "white", fillOpacity: "0.2" }),
        React.createElement("path", { d: "M39.2727 37.8564C39.2727 37.0523 38.6215 36.4004 37.8182 36.4004C37.0149 36.4004 36.3636 37.0523 36.3636 37.8564V56.7844C36.3636 57.5885 37.0149 58.2404 37.8182 58.2404C38.6215 58.2404 39.2727 57.5885 39.2727 56.7844V37.8564Z", fill: "white", fillOpacity: "0.2" })),
    React.createElement("defs", null,
        React.createElement("linearGradient", { id: "paint0_linear_14250_37998", x1: "28", y1: "25.4805", x2: "28", y2: "88.0885", gradientUnits: "userSpaceOnUse" },
            React.createElement("stop", { stopColor: "#B06AA7" }),
            React.createElement("stop", { offset: "0.9999", stopColor: "#E295BE" }),
            React.createElement("stop", { offset: "1", stopColor: "#C4C4C4", "stop-opacity": "0" })),
        React.createElement("clipPath", { id: "clip0_14250_37998" },
            React.createElement("rect", { width: "56", height: "91", fill: "white" })))));
export const LargeBaggageIcon = (React.createElement("svg", { width: "56", height: "101", viewBox: "0 0 56 101", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("g", { clipPath: "url(#clip0_14250_39031)" },
        React.createElement("path", { d: "M32 1.45312H24C20.7867 1.45312 18.1818 4.05567 18.1818 7.26608V26.8848C18.1818 30.0952 20.7867 32.6977 24 32.6977H32C35.2133 32.6977 37.8182 30.0952 37.8182 26.8848V7.26608C37.8182 4.05567 35.2133 1.45312 32 1.45312Z", stroke: "#404C56", strokeWidth: "4" }),
        React.createElement("path", { d: "M13.0909 98.0938H18.1818V99.547C18.1818 100.35 17.5306 101 16.7273 101H14.5455C13.7421 101 13.0909 100.35 13.0909 99.547V98.0938Z", fill: "#404C56" }),
        React.createElement("path", { d: "M37.8182 98.0938H42.9091V99.547C42.9091 100.35 42.2579 101 41.4545 101H39.2727C38.4694 101 37.8182 100.35 37.8182 99.547V98.0938Z", fill: "#404C56" }),
        React.createElement("path", { d: "M0 37.0574C0 25.0184 9.76836 15.2588 21.8182 15.2588H34.1818C46.2316 15.2588 56 25.0184 56 37.0574V77.748C56 82.5633 52.0927 86.4674 47.2727 86.4674H8.72727C3.90733 86.4674 0 82.5633 0 77.748V37.0574Z", fill: "#DF92BC" }),
        React.createElement("path", { d: "M0 41.4177C0 29.3787 9.76836 19.6191 21.8182 19.6191H34.1818C46.2316 19.6191 56 29.3787 56 41.4177V82.1083C56 86.9237 52.0927 90.8278 47.2727 90.8278H8.72727C3.90733 90.8278 0 86.9237 0 82.1083V41.4177Z", fill: "#6A5583" }),
        React.createElement("path", { d: "M0 44.324C0 32.285 9.76836 22.5254 21.8182 22.5254H34.1818C46.2316 22.5254 56 32.285 56 44.324V89.3743C56 94.1896 52.0927 98.0937 47.2727 98.0937H8.72727C3.90733 98.0937 0 94.1896 0 89.3743V44.324Z", fill: "url(#paint0_linear_14250_39031)" }),
        React.createElement("path", { d: "M51.6364 65.3955H4.36364C1.95367 65.3955 0 67.3474 0 69.7552V79.2013C0 81.6091 1.95367 83.561 4.36364 83.561H51.6364C54.0463 83.561 56 81.6091 56 79.2013V69.7552C56 67.3474 54.0463 65.3955 51.6364 65.3955Z", fill: "#6A5583" }),
        React.createElement("path", { d: "M51.6364 65.3955H4.36364C1.95367 65.3955 0 67.3474 0 69.7552V78.4746C0 80.8824 1.95367 82.8343 4.36364 82.8343H51.6364C54.0463 82.8343 56 80.8824 56 78.4746V69.7552C56 67.3474 54.0463 65.3955 51.6364 65.3955Z", fill: "url(#paint1_linear_14250_39031)" }),
        React.createElement("path", { opacity: "0.25", d: "M49.0909 69.0283H6.90909C5.90494 69.0283 5.09091 69.8416 5.09091 70.8449C5.09091 71.8481 5.90494 72.6614 6.90909 72.6614H49.0909C50.0951 72.6614 50.9091 71.8481 50.9091 70.8449C50.9091 69.8416 50.0951 69.0283 49.0909 69.0283Z", fill: "white" }),
        React.createElement("path", { d: "M44.3636 6.54004H33.4545V10.8998H44.3636V6.54004Z", fill: "#404C56" }),
        React.createElement("path", { d: "M38.1136 7.55273H43.7187V8.01777H38.1136V7.55273Z", fill: "white" }),
        React.createElement("path", { d: "M38.1136 8.4873H43.7187V8.95234H38.1136V8.4873Z", fill: "white" }),
        React.createElement("path", { d: "M38.1136 9.4209H43.7187V9.88594H38.1136V9.4209Z", fill: "white" }),
        React.createElement("path", { d: "M19.6364 30.5177C19.6364 29.7151 18.9851 29.0645 18.1818 29.0645C17.3785 29.0645 16.7273 29.7151 16.7273 30.5177V42.8702C16.7273 43.6728 17.3785 44.3234 18.1818 44.3234C18.9851 44.3234 19.6364 43.6728 19.6364 42.8702V30.5177Z", fill: "white", fillOpacity: "0.2" }),
        React.createElement("path", { d: "M26.1818 30.5177C26.1818 29.7151 25.5306 29.0645 24.7273 29.0645C23.924 29.0645 23.2727 29.7151 23.2727 30.5177V42.8702C23.2727 43.6728 23.924 44.3234 24.7273 44.3234C25.5306 44.3234 26.1818 43.6728 26.1818 42.8702V30.5177Z", fill: "white", fillOpacity: "0.2" }),
        React.createElement("path", { d: "M32.7273 30.5177C32.7273 29.7151 32.076 29.0645 31.2727 29.0645C30.4694 29.0645 29.8182 29.7151 29.8182 30.5177V42.8702C29.8182 43.6728 30.4694 44.3234 31.2727 44.3234C32.076 44.3234 32.7273 43.6728 32.7273 42.8702V30.5177Z", fill: "white", fillOpacity: "0.2" }),
        React.createElement("path", { d: "M39.2727 30.5177C39.2727 29.7151 38.6215 29.0645 37.8182 29.0645C37.0149 29.0645 36.3636 29.7151 36.3636 30.5177V42.8702C36.3636 43.6728 37.0149 44.3234 37.8182 44.3234C38.6215 44.3234 39.2727 43.6728 39.2727 42.8702V30.5177Z", fill: "white", fillOpacity: "0.2" })),
    React.createElement("defs", null,
        React.createElement("linearGradient", { id: "paint0_linear_14250_39031", x1: "28", y1: "22.5254", x2: "28", y2: "98.0937", gradientUnits: "userSpaceOnUse" },
            React.createElement("stop", { stopColor: "#E295BE" }),
            React.createElement("stop", { offset: "0.9999", stopColor: "#B674A8" })),
        React.createElement("linearGradient", { id: "paint1_linear_14250_39031", x1: "28", y1: "65.3955", x2: "28", y2: "82.8343", gradientUnits: "userSpaceOnUse" },
            React.createElement("stop", { stopColor: "#E194BE" }),
            React.createElement("stop", { offset: "1", stopColor: "#A979B1" })),
        React.createElement("clipPath", { id: "clip0_14250_39031" },
            React.createElement("rect", { width: "56", height: "101", fill: "white" })))));
export const HandLuggageExcess = (React.createElement("svg", { width: "80", height: "140", viewBox: "0 0 80 140", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { d: "M18.398 44.853L15.6953 82.353C15.3196 87.5649 19.4466 92 24.672 92H56.828C62.0534 92 66.1804 87.5649 65.8047 82.353L63.102 44.853C62.7628 40.1457 58.8448 36.5 54.1253 36.5H27.3747C22.6552 36.5 18.7372 40.1457 18.398 44.853Z", stroke: "currentColor", strokeWidth: "3" }),
    React.createElement("path", { d: "M23.6524 47.597L21.431 80.597C21.1978 84.0615 23.9451 87 27.4175 87H53.5825C57.0549 87 59.8022 84.0615 59.569 80.597L57.3476 47.597C57.1356 44.4469 54.5185 42 51.3612 42H29.6388C26.4815 42 23.8644 44.4469 23.6524 47.597Z", fill: "#D6E9FF" }),
    React.createElement("path", { d: "M27 92V88C27 84.6863 29.6863 82 33 82H49C52.3137 82 55 84.6863 55 88V92", stroke: "currentColor", strokeWidth: "3" }),
    React.createElement("path", { d: "M24 37L26.5296 66.5124C26.7957 69.6161 29.3926 72 32.5077 72H49.4923C52.6074 72 55.2043 69.6161 55.4704 66.5124L58 37", stroke: "currentColor", strokeWidth: "3" }),
    React.createElement("path", { d: "M34 37V35C34 31.6863 36.6863 29 40 29H42C45.3137 29 48 31.6863 48 35V37", stroke: "currentColor", strokeWidth: "3" }),
    React.createElement("path", { d: "M38 73V74C38 75.6569 39.3431 77 41 77V77C42.6569 77 44 75.6569 44 74V73", stroke: "currentColor", strokeWidth: "3.5" }),
    React.createElement("path", { d: "M14.0481 34.2693L6.12311 26.3444M6.12311 26.3444L6.12311 32.4405M6.12311 26.3444L12.2192 26.3444", stroke: "currentColor", strokeWidth: "1.73077" }),
    React.createElement("path", { d: "M14.0481 95.7194L6.12311 103.644M6.12311 103.644L6.12311 97.5483M6.12311 103.644L12.2192 103.644", stroke: "currentColor", strokeWidth: "1.73077" }),
    React.createElement("path", { d: "M66.9519 34.2693L74.8769 26.3444M74.8769 26.3444L74.8769 32.4405M74.8769 26.3444L68.7808 26.3444", stroke: "currentColor", strokeWidth: "1.73077" }),
    React.createElement("path", { d: "M66.9519 95.7194L74.8769 103.644M74.8769 103.644L74.8769 97.5483M74.8769 103.644L68.7808 103.644", stroke: "currentColor", strokeWidth: "1.73077" })));
export const SmallBaggageExcessIcon = (React.createElement("svg", { width: "57", height: "56", viewBox: "0 0 57 56", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("g", null,
        React.createElement("circle", { cx: "28.3333", cy: "28", r: "28", fill: "#C1E5F5" }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M28.3332 4.99316C40.9855 4.99316 51.2423 15.2499 51.2423 27.9023H47.7178H28.3332V8.51764C17.6273 8.51764 8.94855 17.1964 8.94855 27.9023H5.42407C5.42407 15.2499 15.6808 4.99316 28.3332 4.99316Z", fill: "currentColor" }),
        React.createElement("mask", { id: "path-3-outside-1_14342_75268", maskUnits: "userSpaceOnUse", x: "18.3303", y: "9.79794", width: "25.6688", height: "28.2969", fill: "black" },
            React.createElement("rect", { fill: "white", x: "18.3303", y: "9.79794", width: "25.6688", height: "28.2969" }),
            React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M29.8009 22.8219L36.9904 14.6133L32.3869 24.5066C33.8421 26.2427 34.0634 28.7914 32.7626 30.788C31.1688 33.2344 27.8936 33.9256 25.4472 32.3318C23.0008 30.738 22.3096 27.4628 23.9034 25.0164C25.2041 23.0198 27.6249 22.1923 29.8009 22.8219Z" })),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M29.8009 22.8219L36.9904 14.6133L32.3869 24.5066C33.8421 26.2427 34.0634 28.7914 32.7626 30.788C31.1688 33.2344 27.8936 33.9256 25.4472 32.3318C23.0008 30.738 22.3096 27.4628 23.9034 25.0164C25.2041 23.0198 27.6249 22.1923 29.8009 22.8219Z", fill: "currentColor" }),
        React.createElement("path", { d: "M36.9904 14.6133L38.3 15.2227L35.9038 13.6616L36.9904 14.6133ZM29.8009 22.8219L29.3995 24.2094L30.2821 24.4648L30.8875 23.7736L29.8009 22.8219ZM32.3869 24.5066L31.0773 23.8972L30.6897 24.7303L31.2799 25.4345L32.3869 24.5066ZM35.9038 13.6616L28.7143 21.8702L30.8875 23.7736L38.077 15.565L35.9038 13.6616ZM33.6965 25.116L38.3 15.2227L35.6808 14.004L31.0773 23.8972L33.6965 25.116ZM31.2799 25.4345C32.3383 26.6972 32.4965 28.5502 31.5524 29.9995L33.9729 31.5765C35.6302 29.0325 35.346 25.7883 33.4939 23.5787L31.2799 25.4345ZM31.5524 29.9995C30.394 31.7775 28.0137 32.2799 26.2357 31.1215L24.6587 33.5421C27.7736 35.5713 31.9436 34.6913 33.9729 31.5765L31.5524 29.9995ZM26.2357 31.1215C24.4577 29.9632 23.9553 27.5828 25.1137 25.8048L22.6931 24.2279C20.6639 27.3427 21.5439 31.5128 24.6587 33.5421L26.2357 31.1215ZM25.1137 25.8048C26.0578 24.3556 27.8168 23.7515 29.3995 24.2094L30.2024 21.4344C27.4329 20.6331 24.3504 21.684 22.6931 24.2279L25.1137 25.8048Z", fill: "white", mask: "url(#path-3-outside-1_14342_75268)" }))));
export const MiddleBaggageExcessIcon = (React.createElement("svg", { width: "56", height: "56", viewBox: "0 0 56 56", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("g", null,
        React.createElement("circle", { cx: "28", cy: "28", r: "28", fill: "#C1E5F5" }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M27.9999 4.99316C40.6522 4.99316 50.909 15.2499 50.909 27.9023H47.3845H27.9999V8.51764C17.2941 8.51764 8.6153 17.1964 8.6153 27.9023H5.09082C5.09082 15.2499 15.3476 4.99316 27.9999 4.99316Z", fill: "currentColor" }),
        React.createElement("mask", { id: "path-3-outside-1_14342_75291", maskUnits: "userSpaceOnUse", x: "17.3312", y: "11.2064", width: "28.1625", height: "26.5442", fill: "black" },
            React.createElement("rect", { fill: "white", x: "17.3312", y: "11.2064", width: "28.1625", height: "26.5442" }),
            React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M30.495 22.9677L39.9861 17.5836L32.3822 25.4098C33.1847 27.5283 32.5546 30.0077 30.669 31.4648C28.3587 33.2501 25.0385 32.8245 23.2531 30.5142C21.4678 28.2038 21.8934 24.8836 24.2037 23.0983C26.0893 21.6412 28.6475 21.6568 30.495 22.9677Z" })),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M30.495 22.9677L39.9861 17.5836L32.3822 25.4098C33.1847 27.5283 32.5546 30.0077 30.669 31.4648C28.3587 33.2501 25.0385 32.8245 23.2531 30.5142C21.4678 28.2038 21.8934 24.8836 24.2037 23.0983C26.0893 21.6412 28.6475 21.6568 30.495 22.9677Z", fill: "currentColor" }),
        React.createElement("path", { d: "M39.9861 17.5836L41.022 18.5902L39.2733 16.3273L39.9861 17.5836ZM30.495 22.9677L29.6591 24.1457L30.4085 24.6775L31.2077 24.2241L30.495 22.9677ZM32.3822 25.4098L31.3462 24.4033L30.7059 25.0623L31.0314 25.9216L32.3822 25.4098ZM24.2037 23.0983L25.087 24.2412L24.2037 23.0983ZM39.2733 16.3273L29.7823 21.7113L31.2077 24.2241L40.6988 18.84L39.2733 16.3273ZM33.4182 26.4164L41.022 18.5902L38.9501 16.5771L31.3462 24.4033L33.4182 26.4164ZM31.0314 25.9216C31.6151 27.4623 31.1544 29.2642 29.7858 30.3218L31.5522 32.6077C33.9547 30.7512 34.7543 27.5942 33.7329 24.8981L31.0314 25.9216ZM29.7858 30.3218C28.1067 31.6193 25.6936 31.31 24.3961 29.6309L22.1102 31.3974C24.3833 34.339 28.6107 34.8808 31.5522 32.6077L29.7858 30.3218ZM24.3961 29.6309C23.0985 27.9518 23.4079 25.5388 25.087 24.2412L23.3205 21.9553C20.3789 24.2285 19.8371 28.4558 22.1102 31.3974L24.3961 29.6309ZM25.087 24.2412C26.4556 23.1836 28.3154 23.1923 29.6591 24.1457L31.3309 21.7897C28.9796 20.1213 25.7229 20.0988 23.3205 21.9553L25.087 24.2412Z", fill: "white", mask: "url(#path-3-outside-1_14342_75291)" }))));
export const LargeBaggageExcessIcon = (React.createElement("svg", { width: "57", height: "56", viewBox: "0 0 57 56", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("g", null,
        React.createElement("circle", { cx: "28.6665", cy: "28", r: "28", fill: "#C1E5F5" }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M28.6664 4.99316C41.3188 4.99316 51.5755 15.2499 51.5755 27.9023H48.051H28.6664V8.51764C17.9606 8.51764 9.2818 17.1964 9.2818 27.9023H5.75732C5.75732 15.2499 16.0141 4.99316 28.6664 4.99316Z", fill: "currentColor" }),
        React.createElement("mask", { id: "path-3-outside-1_14342_75094", maskUnits: "userSpaceOnUse", x: "21.1333", y: "20.6152", width: "24", height: "15", fill: "black" },
            React.createElement("rect", { fill: "white", x: "21.1333", y: "20.6152", width: "24", height: "15" }),
            React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M33.3311 26.3588L44.1333 27.9019L33.3311 29.4451C32.6708 31.6121 30.6561 33.1887 28.2732 33.1887C25.3534 33.1887 22.9864 30.8217 22.9864 27.9019C22.9864 24.9822 25.3534 22.6152 28.2732 22.6152C30.6561 22.6152 32.6708 24.1918 33.3311 26.3588Z" })),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M33.3311 26.3588L44.1333 27.9019L33.3311 29.4451C32.6708 31.6121 30.6561 33.1887 28.2732 33.1887C25.3534 33.1887 22.9864 30.8217 22.9864 27.9019C22.9864 24.9822 25.3534 22.6152 28.2732 22.6152C30.6561 22.6152 32.6708 24.1918 33.3311 26.3588Z", fill: "currentColor" }),
        React.createElement("path", { d: "M44.1333 27.9019L44.3376 29.3319L44.3376 26.472L44.1333 27.9019ZM33.3311 26.3588L31.9494 26.7798L32.2172 27.6588L33.1268 27.7887L33.3311 26.3588ZM33.3311 29.4451L33.1268 28.0152L32.2172 28.1451L31.9494 29.0241L33.3311 29.4451ZM44.3376 26.472L33.5354 24.9288L33.1268 27.7887L43.929 29.3319L44.3376 26.472ZM33.5354 30.875L44.3376 29.3319L43.929 26.472L33.1268 28.0152L33.5354 30.875ZM31.9494 29.0241C31.4691 30.6001 30.0029 31.7442 28.2732 31.7442L28.2732 34.6331C31.3093 34.6331 33.8725 32.624 34.7128 29.8661L31.9494 29.0241ZM28.2732 31.7442C26.1511 31.7442 24.4309 30.024 24.4309 27.9019L21.542 27.9019C21.542 31.6195 24.5556 34.6331 28.2732 34.6331L28.2732 31.7442ZM24.4309 27.9019C24.4309 25.7799 26.1511 24.0597 28.2732 24.0597L28.2732 21.1708C24.5556 21.1708 21.542 24.1844 21.542 27.9019L24.4309 27.9019ZM28.2732 24.0597C30.0029 24.0597 31.4691 25.2038 31.9494 26.7798L34.7128 25.9377C33.8725 23.1799 31.3093 21.1708 28.2732 21.1708L28.2732 24.0597Z", fill: "white", mask: "url(#path-3-outside-1_14342_75094)" }))));

import { __awaiter } from "tslib";
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { InsurancePageModal, InsuranceUpsaleModal } from '../../../InsurancePage';
import { ThemeProvider, useTheme } from '../../../theme';
import { Money } from '../../../Money';
import { useInsuranceGroupTexts, useInsuranceUpsale } from './hooks';
import SimpleLoader from '../../../SimpleLoader';
import Modal from '../../../Modal';
import ServiceSummaryDivider from '../../../ServiceSummary/components/ServiceSummaryDivider/ServiceSummaryDivider';
import ServiceSummaryOption from '../../../ServiceSummary/components/ServiceSummaryOption';
import InsuranceSummary from '../../../ServiceSummary/services/InsuranceSummary';
import Button from '../../../Button';
import { Included } from '../../../Icons';
import { OptionsList } from '../../../InsurancePage/components/OptionsList/OptionsList';
import { getInsuranceGroupNameAttr } from './utils';
import { useIataCode } from '../../../hooks';
const isAlwaysOpenInsurancePopup = (iataCode) => ['Y7'].includes(iataCode);
const InsuranceUpsale = ({ insurancePrograms, oldVersion, groupName }) => {
    const { t } = useTranslation('Checkout');
    const { InsuranceUpsale: theme } = useTheme('CheckoutAdditionalService');
    const iataCode = useIataCode();
    const model = useInsuranceUpsale(insurancePrograms);
    const groupTexts = useInsuranceGroupTexts(groupName, insurancePrograms);
    const onServiceDelete = (_, service) => __awaiter(void 0, void 0, void 0, function* () {
        const insuranceService = service;
        yield model.onDeleteInsurance(insuranceService.code);
    });
    const renderPriceDescription = (isSelected) => {
        return isSelected ? (model.totalPrice.amount > 0 && (React.createElement("div", { className: theme.price },
            React.createElement("span", { className: theme.price__label }, t('Total')),
            React.createElement(Money, { moneyClassName: theme.money, money: model.totalPrice }),
            React.cloneElement(Included, { className: theme.includedIcon })))) : (React.createElement(React.Fragment, null,
            React.createElement("span", { className: theme.forAll }, t('For all passengers')),
            React.createElement("div", { className: theme.price },
                model.isDifferentPrices && React.createElement("span", { className: theme.price__label }, t('from')),
                React.createElement(Money, { moneyClassName: theme.money, money: model.minPrice }))));
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(InsuranceSummary, { flight: model.order.flight, travellers: model.order.travellers, services: model.insuranceSummaryServices, onServiceDelete: model.totalPrice.amount > 0 ? onServiceDelete : null, isConfirmed: model.isConfirmed, isDownload: true }, items => {
            const isSelected = items.length > 0;
            return (React.createElement(ThemeProvider, { value: {
                    ServiceSummary: {
                        ServiceSummaryOption: {
                            option__name: theme.option__name,
                            option__price: theme.option__price,
                            option__price_icon: theme.option__price_icon,
                            option__download: theme.option__download,
                            option__iconIncluded: theme.option__iconIncluded
                        }
                    }
                } },
                React.createElement("div", Object.assign({ className: cn(theme.container, {
                        [theme.container_selected]: isSelected,
                        [theme.oldVersion]: oldVersion,
                        [theme.container_loading]: model.isLoading
                    }, groupName) }, getInsuranceGroupNameAttr(groupName)),
                    React.createElement("div", { className: theme.header },
                        groupTexts.title,
                        oldVersion && renderPriceDescription(isSelected)),
                    React.createElement("div", { className: theme.content },
                        isSelected && model.order.travellers.length > 1 && (React.createElement("div", { className: theme.label }, t('For all passengers'))),
                        isSelected ? (React.createElement(ServiceSummaryDivider, null,
                            React.createElement(ServiceSummaryOption, { groupOption: { items } }))) : (React.createElement("span", { dangerouslySetInnerHTML: { __html: groupTexts.descr } })),
                        !isSelected && (React.createElement(OptionsList, { className: theme.options, options: groupTexts.options }))),
                    React.createElement("div", { className: theme.footer },
                        !oldVersion && (React.createElement("div", { className: theme.info }, renderPriceDescription(isSelected))),
                        React.createElement("div", { className: theme.controls },
                            React.createElement(Button, { className: cn(theme.button, {
                                    [theme.change]: isSelected,
                                    [theme.loading]: model.isLoading
                                }), onClick: model.singleInsurance && !isAlwaysOpenInsurancePopup(iataCode)
                                    ? isSelected
                                        ? model.onDeleteSingle
                                        : model.onAddSingle
                                    : model.open, isLoading: model.isLoading }, model.singleInsurance && !isAlwaysOpenInsurancePopup(iataCode)
                                ? isSelected
                                    ? t('Remove protection')
                                    : t('Add protection')
                                : isSelected
                                    ? t('Change')
                                    : t('Add protection')))))));
        }),
        React.createElement(Modal, { classes: { scrollBody: theme.scrollBody, paper: theme.paper }, open: model.isOpen, fullScreen: true },
            React.createElement(InsurancePageModal, { availablePrograms: model.availablePrograms, onServicesChanged: model.setIsNeedToSendSaveReq, onTotalInsurancePriceChange: model.setInsurancePriceToPay, onSaveOrderServicesParamsChange: model.setSaveOrderServicesParams, onDownloadNasInsurance: model.onDownloadVskInsurance, isShowImportantInsurancesError: model.isShowImportantInsurancesError, totalPrice: model.insurancePriceToPay, onConfirm: model.onConfirm, onClose: model.onClose }),
            model.isLoading && React.createElement(SimpleLoader, null)),
        React.createElement(InsuranceUpsaleModal, { insurance: model.priorityInsurance, isOpen: model.isUpsaleOpen, onAgree: model.handleAgree, onDisagree: model.handleDisagree })));
};
export default InsuranceUpsale;

import * as React from 'react';
import cn from 'classnames';
import { FareFeaturePaymentType } from '@websky/graphql';
import { checkIcon, dollarIcon, noIcon, rubleIcon } from '@websky/core/src/CompareFares/Icons';
import { useTheme } from '@websky/core/src/theme';
import { useConfig } from '@websky/core/src/context';
const availabilityIcons = {
    [FareFeaturePaymentType.Free]: checkIcon,
    [FareFeaturePaymentType.Pay]: dollarIcon,
    [FareFeaturePaymentType.NotAvailable]: noIcon
};
const Cell = ({ cell, isFullFareCondition, customAvailabilityIcons }) => {
    const { CompareFares: css } = useTheme('CompareFares');
    // TODO: wz-master костыль, привести к состоянию мастера
    // const icons = customAvailabilityIcons ?? availabilityIcons;
    const iataCode = useConfig().global.companyInfo.iataCode;
    const isY7 = iataCode === 'Y7';
    if (cell === null) {
        return (React.createElement("div", { className: cn(css.cell, {
                [css.cell_notFullFareConditions]: !isFullFareCondition
            }) },
            React.createElement("div", { className: css.cellContent }, '')));
    }
    const { description, value, availability } = cell;
    return (React.createElement("div", { className: cn(css.cell, {
            [css.cell_notFullFareConditions]: !isFullFareCondition
        }) },
        isFullFareCondition && (React.createElement("div", { className: css.col },
            React.createElement("div", { className: cn(css.cellContent, css.cellContent_fullDescr) }, description))),
        !isFullFareCondition && (React.createElement("div", { className: css.cellContent },
            value && React.createElement("span", null, value),
            React.createElement("div", { className: css.availabilityIcon },
                !value && availability === FareFeaturePaymentType.Free && React.createElement(React.Fragment, null, checkIcon),
                !value && availability === FareFeaturePaymentType.Pay && React.createElement(React.Fragment, null, isY7 ? rubleIcon : dollarIcon),
                !value && availability === FareFeaturePaymentType.NotAvailable && React.createElement(React.Fragment, null, noIcon))))));
};
export default Cell;

import * as React from 'react';
import { LocationType } from '@websky/core/src/types';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import css from './Value.css';
const Value = ({ isOpen, isLoading, location, inputValue, type, withMultiCity, onClick }) => {
    const { t } = useTranslation('SearchForm');
    return (React.createElement("div", { className: cn(css.value, { [css.value_open]: isOpen }), onClick: onClick },
        React.createElement("div", { className: css.label }, t(type === LocationType.Departure ? 'From' : 'To')),
        !isOpen && !location && (React.createElement("div", { className: css.placeholder }, t(type === LocationType.Departure ? 'Departure airport or city' : 'Arrival airport or city'))),
        !isOpen && location && (React.createElement(React.Fragment, null,
            React.createElement("div", { className: css.iata }, location.iata),
            React.createElement("div", { className: css.city }, location.name)))));
};
export default Value;

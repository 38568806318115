import React from 'react';
import { useBaggageSize } from '@websky/core/src/Baggage/Baggage/hooks';
import { BaggageSize } from '@websky/core/src/Baggage/Baggage/types';
import { BaggageType } from '@websky/graphql';
import { HandLuggageExcess, LargeBaggageExcessIcon, LargeBaggageIcon, LargeCarryOnIcon, MiddleBaggageExcessIcon, MiddleBaggageIcon, SmallBaggageExcessIcon, SmallBaggageIcon, SmallCarryOnIcon } from './icons';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { useTheme } from '@websky/core/src/theme';
const defaultIcons = new Map([
    [
        BaggageType.CheckedBaggage,
        new Map([
            [BaggageSize.small, SmallBaggageIcon],
            [BaggageSize.middle, MiddleBaggageIcon],
            [BaggageSize.large, LargeBaggageIcon]
        ])
    ],
    [
        BaggageType.CarryOn,
        new Map([
            [BaggageSize.small, SmallCarryOnIcon],
            [BaggageSize.large, LargeCarryOnIcon]
        ])
    ],
    [
        BaggageType.HandLuggageExcess,
        new Map([
            [BaggageSize.small, SmallCarryOnIcon],
            [BaggageSize.large, HandLuggageExcess]
        ])
    ],
    [
        BaggageType.BaggageExcess,
        new Map([
            [BaggageSize.small, SmallBaggageExcessIcon],
            [BaggageSize.middle, MiddleBaggageExcessIcon],
            [BaggageSize.large, LargeBaggageExcessIcon]
        ])
    ]
]);
const BaggageIcon = ({ baggage, className, realSize, onlyIcon, iconsMap = defaultIcons }) => {
    var _a;
    const { t } = useTranslation();
    const { BaggageIcon: css } = useTheme('Baggage');
    const size = useBaggageSize(baggage);
    const isCarryOn = [BaggageType.CarryOn, BaggageType.HandLuggageExcess].includes(baggage.type);
    return (React.createElement("div", { className: cn(css.root, className, {
            [css.checked_baggage]: baggage.type === BaggageType.CheckedBaggage,
            [css.carry_on]: isCarryOn,
            [css.small]: size === BaggageSize.small,
            [css.middle]: size === BaggageSize.middle,
            [css.large]: size === BaggageSize.large,
            [css.real_size]: realSize,
            [css === null || css === void 0 ? void 0 : css[baggage.type]]: css === null || css === void 0 ? void 0 : css[baggage.type]
        }) },
        React.createElement("div", { className: css.wrapper }, (_a = iconsMap.get(baggage.type)) === null || _a === void 0 ? void 0 :
            _a.get(size),
            !onlyIcon && (React.createElement("div", { className: cn(css.size) },
                React.createElement("div", { className: css.value }, baggage.value.amount),
                React.createElement("div", { className: css.measurement }, t(baggage.value.measurement)))))));
};
export default BaggageIcon;

import React from 'react';
import { FareFeaturePaymentType } from '@websky/graphql';
export default {
    [FareFeaturePaymentType.Free]: (React.createElement("svg", { width: "20", height: "18", viewBox: "0 0 20 18", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M10.0108 18C15.0547 18 19.1436 13.9706 19.1436 9C19.1436 4.02944 15.0547 0 10.0108 0C4.96684 0 0.87793 4.02944 0.87793 9C0.87793 13.9706 4.96684 18 10.0108 18ZM15.1283 5.91725C15.4182 5.6173 15.4064 5.14257 15.1021 4.85691C14.7977 4.57124 14.316 4.58282 14.0261 4.88277L8.48863 10.6125L5.99547 8.03277C5.70559 7.73282 5.22385 7.72124 4.91948 8.00691C4.6151 8.29257 4.60335 8.7673 4.89323 9.06725L7.93751 12.2173C8.08116 12.3659 8.28034 12.45 8.48863 12.45C8.69692 12.45 8.8961 12.3659 9.03975 12.2173L15.1283 5.91725Z", fill: "currentColor" }))),
    [FareFeaturePaymentType.Pay]: (React.createElement("svg", { width: "512", height: "512", viewBox: "0 0 512 512", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M512 256C512 397.385 397.385 512 256 512C114.615 512 0 397.385 0 256C0 114.615 114.615 0 256 0C397.385 0 512 114.615 512 256ZM390.427 188.72C390.427 250.419 340.511 300.44 278.707 300.44H221.726V324.426H289.953C295.752 324.426 300.453 329.127 300.453 334.926V379.913C300.453 385.712 295.752 390.413 289.953 390.413H221.727V424.9C221.727 430.699 217.026 435.4 211.227 435.4H166.24C160.441 435.4 155.74 430.699 155.74 424.9V390.414H132.5C126.701 390.414 122 385.713 122 379.914V334.927C122 329.128 126.701 324.427 132.5 324.427H155.74V300.44H132.5C126.701 300.44 122 295.739 122 289.94V244.953C122 239.154 126.701 234.453 132.5 234.453H155.74V87.5C155.74 81.7012 160.441 77 166.24 77H278.707C340.31 77 390.427 127.117 390.427 188.72ZM278.706 142.987H221.726V234.453H278.706C303.923 234.453 324.439 213.937 324.439 188.72C324.439 163.503 303.923 142.987 278.706 142.987Z", fill: "currentColor" }))),
    [FareFeaturePaymentType.NotAvailable]: (React.createElement("svg", { width: "20", height: "18", viewBox: "0 0 16 16", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M13.3335 7.99992C13.3335 10.9454 10.9457 13.3333 8.00016 13.3333C6.83851 13.3333 5.7636 12.9619 4.88769 12.3313L12.3316 4.88745C12.9621 5.76336 13.3335 6.83827 13.3335 7.99992ZM3.91026 11.4232L11.4234 3.91002C10.4971 3.13388 9.30319 2.66659 8.00016 2.66659C5.05464 2.66659 2.66683 5.0544 2.66683 7.99992C2.66683 9.30295 3.13412 10.4968 3.91026 11.4232ZM14.6668 7.99992C14.6668 11.6818 11.6821 14.6666 8.00016 14.6666C4.31826 14.6666 1.3335 11.6818 1.3335 7.99992C1.3335 4.31802 4.31826 1.33325 8.00016 1.33325C11.6821 1.33325 14.6668 4.31802 14.6668 7.99992Z", fill: "currentColor" })))
};

import { __rest } from "tslib";
import React from 'react';
import { useSelector } from 'react-redux';
import CheckoutAdditionalService from '@websky/core/src/CheckoutAdditionalService/CheckoutAdditionalService/CheckoutAdditionalService';
import { getPassengerStateCompatible } from '@websky/core/src/Checkout/store/selectedServices/selectors';
import { getSeatMap } from '@websky/core/src/Checkout/store/order/selectors';
const SeatsAdditionalService = (_a) => {
    var { showAddButton, isSelected } = _a, props = __rest(_a, ["showAddButton", "isSelected"]);
    const passengers = useSelector(getPassengerStateCompatible);
    const seatMap = useSelector(getSeatMap);
    const hasAvailableSeatsInSeatMap = seatMap.segments.some(segment => { var _a; return (_a = segment.decks) === null || _a === void 0 ? void 0 : _a.some(deck => { var _a; return (_a = deck.rows) === null || _a === void 0 ? void 0 : _a.some(row => { var _a; return (_a = row.seats) === null || _a === void 0 ? void 0 : _a.length; }); }); });
    const passengersHasSelectedSeats = passengers.some(passenger => { var _a; return (_a = passenger.seats) === null || _a === void 0 ? void 0 : _a.length; });
    return (React.createElement(CheckoutAdditionalService, Object.assign({}, props, { showAddButton: showAddButton || (!passengersHasSelectedSeats && hasAvailableSeatsInSeatMap), isSelected: isSelected })));
};
export default SeatsAdditionalService;

import { __rest } from "tslib";
import * as React from 'react';
import { addMonths, differenceInCalendarDays, endOfMonth, format, startOfMonth } from 'date-fns';
import { PriceGraph as PriceGraphComponent, RouteType } from '../../../../../PriceGraph';
import DummyPriceGraph from '../DummyPriceGraph/DummyPriceGraph';
import { sortSegments } from '../utils';
import { API_DATE_FORMAT } from '../../../../../utils';
import EmptyDatesOverlay from '../../../../../SearchFormDatepicker/components/EmptyDatesOverlay/EmptyDatesOverlay';
import { useMinPricesQuery } from '@websky/graphql';
const PriceGraph = ({ searchParameters, legId, startNewSearch, onClose }) => {
    const { segments, promotionCode } = searchParameters, restSearchParameters = __rest(searchParameters, ["segments", "promotionCode"]);
    const centralDate = new Date(searchParameters.segments[legId].date);
    const startDate = startOfMonth(new Date());
    // Remove all segments from request except the current one.
    const request = Object.assign(Object.assign({}, restSearchParameters), { segments: [Object.assign(Object.assign({}, segments[legId]), { date: format(startDate, API_DATE_FORMAT) })] });
    const onDateClick = (date) => {
        const segments = [...searchParameters.segments];
        segments[legId] = Object.assign(Object.assign({}, segments[legId]), { date: format(date, API_DATE_FORMAT) });
        const segmentsWithRightOrder = sortSegments(legId, date, segments);
        startNewSearch(Object.assign(Object.assign({}, searchParameters), { segments: segmentsWithRightOrder }));
    };
    const { data, loading } = useMinPricesQuery({
        variables: {
            params: Object.assign(Object.assign({}, request), { daysCount: differenceInCalendarDays(endOfMonth(addMonths(centralDate, 6)), startDate) })
        },
        context: {
            currency: request.currency
        }
    });
    if (loading) {
        return React.createElement(DummyPriceGraph, null);
    }
    if (!data || !data.FlightsMinPricesInPeriod || !data.FlightsMinPricesInPeriod.datesWithLowestPrices.length) {
        return React.createElement(EmptyDatesOverlay, { onClick: onClose });
    }
    const prices = {};
    data.FlightsMinPricesInPeriod.datesWithLowestPrices.forEach(item => {
        prices[new Date(item.date).toISOString()] = {
            price: item.price,
            hasFlight: false // we don't need this property in priceGraph
        };
    });
    return (React.createElement(PriceGraphComponent, { openDate: centralDate, outDate: legId > 0 ? new Date(searchParameters.segments[legId - 1].date) : null, routeType: RouteType.Outbound, onSelect: onDateClick, prices: prices }));
};
export default PriceGraph;

import React from 'react';
import { FareFamilyOptionsType } from '@websky/graphql';
import { PhoneSuccess, SportEquipment } from '@websky/core/src/Icons';
export default {
    [FareFamilyOptionsType.VipService]: (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M5.84621 9.91304C5.84621 6.64735 8.60136 4 12 4C15.3987 4 18.1539 6.64735 18.1539 9.91304L18.1538 13.6087H5.84621V9.91304ZM19.6923 15.087V10.4059H22V15.3334C22 16.711 21.0194 17.8685 19.6923 18.1967V21H18.1539V18.2899H5.84621V21H4.30773V18.1967C2.98063 17.8685 2 16.711 2 15.3333H2.00004V10.4059H4.30773V15.087H19.6923Z", fill: "currentColor" }))),
    [FareFamilyOptionsType.CarryOn]: (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", version: "1.1", width: "512", height: "512", x: "0", y: "0", viewBox: "0 0 512 512" },
        React.createElement("path", { d: "M159.479,230.4h-45.892c-10.871,0-19.721,8.841-19.721,19.712v45.901c0,10.88,8.849,19.721,19.721,19.721h45.892    c10.871,0,19.721-8.841,19.721-19.712v-45.901C179.2,239.241,170.351,230.4,159.479,230.4z M162.133,296.013    c0,1.459-1.195,2.654-2.654,2.654h-45.892c-1.459,0-2.654-1.195-2.654-2.645v-45.901c0-1.459,1.195-2.654,2.654-2.654h45.892    c1.459,0,2.654,1.195,2.654,2.645V296.013z", fill: "currentColor" }),
        React.createElement("path", { d: "M398.413,230.4h-45.892c-10.872,0-19.721,8.841-19.721,19.712v45.901c0,10.88,8.849,19.721,19.721,19.721h45.892    c10.871,0,19.721-8.841,19.721-19.712v-45.901C418.133,239.241,409.284,230.4,398.413,230.4z M401.067,296.013    c0,1.459-1.195,2.654-2.654,2.654h-45.892c-1.459,0-2.654-1.195-2.654-2.645v-45.901c0-1.459,1.195-2.654,2.654-2.654h45.892    c1.459,0,2.654,1.195,2.654,2.645V296.013z", fill: "currentColor" }),
        React.createElement("path", { d: "M452.267,179.2H59.733c-4.71,0-8.533,3.814-8.533,8.533v59.759c0,18.808,15.3,34.108,34.108,34.108H102.4    c4.71,0,8.533-3.814,8.533-8.533v-22.946c0-1.459,1.195-2.654,2.654-2.654h45.892c1.459,0,2.654,1.195,2.654,2.654v22.946    c0,4.719,3.823,8.533,8.533,8.533h170.667c4.71,0,8.533-3.814,8.533-8.533v-22.946c0-1.459,1.195-2.654,2.654-2.654h45.892    c1.459,0,2.654,1.195,2.654,2.654v22.946c0,4.719,3.823,8.533,8.533,8.533h17.092c18.807,0,34.108-15.3,34.108-34.108v-59.759    C460.8,183.014,456.977,179.2,452.267,179.2z M443.733,247.492c0,9.395-7.646,17.041-17.041,17.041h-8.559v-14.413    c0-10.88-8.849-19.721-19.721-19.721h-45.892c-10.872,0-19.721,8.841-19.721,19.721v14.413H179.2v-14.413    c0-10.88-8.849-19.721-19.721-19.721h-45.892c-10.871,0-19.721,8.841-19.721,19.721v14.413h-8.559    c-9.395,0-17.041-7.646-17.041-17.041v-51.226h375.467V247.492z", fill: "currentColor" }),
        React.createElement("path", { d: "M136.533,273.067c-4.71,0-8.533,3.814-8.533,8.533v25.6c0,4.719,3.823,8.533,8.533,8.533s8.533-3.814,8.533-8.533v-25.6    C145.067,276.881,141.244,273.067,136.533,273.067z", fill: "currentColor" }),
        React.createElement("path", { d: "M375.467,273.067c-4.71,0-8.533,3.814-8.533,8.533v25.6c0,4.719,3.823,8.533,8.533,8.533S384,311.919,384,307.2v-25.6    C384,276.881,380.177,273.067,375.467,273.067z", fill: "currentColor" }),
        React.createElement("path", { d: "M460.783,179.2H51.217C22.972,179.2,0,202.172,0,230.417v204.766C0,463.428,22.972,486.4,51.217,486.4h409.566    c28.245,0,51.217-22.972,51.217-51.217V230.417C512,202.172,489.028,179.2,460.783,179.2z M494.933,435.183    c0,18.833-15.317,34.15-34.15,34.15H51.217c-18.833,0-34.15-15.317-34.15-34.15V230.417c0-18.833,15.317-34.15,34.15-34.15    h409.566c18.833,0,34.15,15.317,34.15,34.15V435.183z", fill: "currentColor" }),
        React.createElement("path", { d: "M153.6,298.667h-34.133c-4.71,0-8.533,3.814-8.533,8.533v170.667c0,4.719,3.823,8.533,8.533,8.533H153.6    c4.71,0,8.533-3.814,8.533-8.533V307.2C162.133,302.481,158.31,298.667,153.6,298.667z M145.067,469.333H128v-153.6h17.067    V469.333z", fill: "currentColor" }),
        React.createElement("path", { d: "M392.533,298.667H358.4c-4.71,0-8.533,3.814-8.533,8.533v170.667c0,4.719,3.823,8.533,8.533,8.533h34.133    c4.71,0,8.533-3.814,8.533-8.533V307.2C401.067,302.481,397.244,298.667,392.533,298.667z M384,469.333h-17.067v-153.6H384    V469.333z", fill: "currentColor" }),
        React.createElement("path", { d: "M315.733,366.933H196.267c-4.71,0-8.533,3.814-8.533,8.533s3.823,8.533,8.533,8.533h119.467    c4.71,0,8.533-3.814,8.533-8.533S320.444,366.933,315.733,366.933z", fill: "currentColor" }),
        React.createElement("path", { d: "M256,25.6c-75.29,0-136.533,61.252-136.533,136.533v25.6c0,4.719,3.823,8.533,8.533,8.533h34.133    c4.676,0,8.482-3.755,8.533-8.439l0.35-33.459C174.95,110.874,212.284,76.8,256,76.8c43.716,0,81.05,34.074,84.949,76.894    l0.384,34.133c0.051,4.685,3.857,8.439,8.533,8.439H384c4.71,0,8.533-3.814,8.533-8.533v-25.6    C392.533,86.852,331.29,25.6,256,25.6z M375.467,179.2h-17.169l-0.316-26.368C353.254,100.625,308.463,59.733,256,59.733    s-97.254,40.892-102.016,93.773l-0.29,25.694h-17.161v-17.067c0-65.877,53.589-119.467,119.467-119.467    s119.467,53.589,119.467,119.467V179.2z", fill: "currentColor" }),
        React.createElement("path", { d: "M281.6,366.933c-14.353,0-25.6,14.993-25.6,34.133c0,19.14,11.247,34.133,25.6,34.133c14.353,0,25.6-14.993,25.6-34.133    C307.2,381.926,295.953,366.933,281.6,366.933z M281.6,418.133c-3.482,0-8.533-6.647-8.533-17.067    c0-10.419,5.052-17.067,8.533-17.067c3.482,0,8.533,6.647,8.533,17.067C290.133,411.486,285.082,418.133,281.6,418.133z", fill: "currentColor" }))),
    [FareFamilyOptionsType.Baggage]: (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", version: "1.1", width: "512", height: "512", x: "0", y: "0", viewBox: "0 0 489.805 489.805" },
        React.createElement("path", { d: "M299.304,0h-108.8c-5.891,0-10.667,4.776-10.667,10.667c0,5.891,4.776,10.667,10.667,10.667h108.8    c5.891,0,10.667-4.776,10.667-10.667C309.971,4.776,305.195,0,299.304,0z", fill: "currentColor" }),
        React.createElement("rect", { x: "194.984", y: "29.653", width: "21.333", height: "44.8", fill: "currentColor" }),
        React.createElement("rect", { x: "273.491", y: "29.653", width: "21.333", height: "44.8", fill: "currentColor" }),
        React.createElement("path", { d: "M351.571,82.133H138.237c-23.564,0-42.667,19.103-42.667,42.667v292.267c-0.216,12.336,4.919,24.162,14.08,32.427    c0.128,22.386,18.379,40.429,40.765,40.301c18.102-0.104,33.941-12.198,38.809-29.634h111.36    c6.019,21.562,28.378,34.161,49.939,28.142c17.436-4.867,29.531-20.706,29.634-38.809c9.105-8.215,14.234-19.951,14.08-32.213    V124.8C394.237,101.236,375.135,82.133,351.571,82.133z M151.251,469.333h-1.067c-10.604,0-19.2-8.596-19.2-19.2    c0.095-1.82,0.454-3.617,1.067-5.333c0.262-1.096,0.619-2.166,1.067-3.2c0.602-1.135,1.317-2.207,2.133-3.2l2.987-2.347    c1.172-1.005,2.462-1.864,3.84-2.56l2.133-1.28c2.23-0.942,4.62-1.449,7.04-1.493c10.663,0,19.307,8.644,19.307,19.307    S161.914,469.333,151.251,469.333z M339.624,469.333c-10.604-0.082-19.133-8.744-19.051-19.348    c0.082-10.604,8.744-19.133,19.348-19.051c2.306,0.018,4.59,0.451,6.743,1.279l2.133,1.28c1.292,0.645,2.509,1.432,3.627,2.347    l2.133,2.347c0.806,0.924,1.52,1.925,2.133,2.987c0.532,1.096,0.96,2.238,1.28,3.413c0.616,1.716,0.975,3.513,1.067,5.333    C359.037,460.642,350.346,469.333,339.624,469.333z M372.904,417.067c-0.005,2.86-0.586,5.689-1.707,8.32l-1.28-1.28    c-1.247-1.518-2.601-2.944-4.053-4.267l-2.347-1.92l-4.693-2.987l-2.773-1.493l-5.547-2.133h-2.773    c-2.977-0.331-5.982-0.331-8.96,0c-4.139,0.076-8.242,0.796-12.16,2.133h-2.133c-3.577,1.296-6.953,3.091-10.027,5.333    l-1.707,1.28c-3.025,2.453-5.684,5.326-7.893,8.533c-2.145,3.299-3.797,6.892-4.907,10.667v1.28H189.437v-1.28    c-1.109-3.775-2.762-7.368-4.907-10.667c-2.21-3.207-4.868-6.081-7.893-8.533l-1.707-1.28c-3.074-2.242-6.45-4.038-10.027-5.333    h-2.133c-6.96-2.075-14.374-2.075-21.333,0h-2.773l-5.333,1.92l-3.2,0.64l-4.48,2.987l-2.56,2.133l-3.84,4.053l-2.347,0.427    c-0.546-2.746-0.546-5.574,0-8.32V124.8c0-11.782,9.551-21.333,21.333-21.333h213.333c11.782,0,21.333,9.551,21.333,21.333    V417.067z", fill: "currentColor" }),
        React.createElement("path", { d: "M179.411,160.427c-5.891,0-10.667,4.776-10.667,10.667v199.893c0,5.891,4.776,10.667,10.667,10.667    c5.891,0,10.667-4.776,10.667-10.667V171.093C190.077,165.202,185.302,160.427,179.411,160.427z", fill: "currentColor" }),
        React.createElement("path", { d: "M244.904,160.427c-5.891,0-10.667,4.776-10.667,10.667v199.893c0,5.891,4.776,10.667,10.667,10.667    s10.667-4.776,10.667-10.667V171.093C255.571,165.202,250.795,160.427,244.904,160.427z", fill: "currentColor" }),
        React.createElement("path", { d: "M310.397,160.427c-5.891,0-10.667,4.776-10.667,10.667v199.893c0,5.891,4.776,10.667,10.667,10.667    c5.891,0,10.667-4.776,10.667-10.667V171.093C321.064,165.202,316.288,160.427,310.397,160.427z", fill: "currentColor" }))),
    [FareFamilyOptionsType.SeatRegistration]: (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", version: "1.1", width: "512", height: "512", x: "0", y: "0", viewBox: "0 0 512 512" },
        React.createElement("g", { transform: "matrix(-1,0,0,1,512,0)" },
            React.createElement("path", { d: "M502,492c-5.51,0-10,4.49-10,10c0,5.51,4.49,10,10,10c5.51,0,10-4.49,10-10C512,496.49,507.51,492,502,492z", fill: "currentColor" }),
            React.createElement("path", { d: "M423.036,0c-28.102,0-50.964,22.862-50.964,50.964v45.744l-61.547,84.956H168c-16.542,0-30,13.458-30,30s13.458,30,30,30   h99.058L245.081,272H108c-38.598,0-70,31.402-70,70s31.402,70,70,70h128.176l14.559,40H128c-16.542,0-30,13.458-30,30   c0,3.506,0.61,6.87,1.72,10H10c-5.51,0-10,4.49-10,10c0,5.51,4.49,10,10,10h452c5.51,0,10-4.49,10-10c0-5.51-4.49-10-10-10H316.28   c1.11-3.13,1.72-6.494,1.72-10v-20c0-1.18-0.209-2.35-0.617-3.458L300.234,412H308l0,0l0.001,0c3.71,0,7.135-2.06,8.865-5.376   l156.012-301.022c0.737-1.422,1.122-3,1.122-4.602c0-0.202-0.003-0.4-0.008-0.598c0.005-0.134,0.008-0.268,0.008-0.402V50.964   C474,22.862,451.138,0,423.036,0z M168,221.663c-5.514,0-10-4.486-10-10s4.486-10,10-10h180.464l-13.722,20H168z M129.72,392H108   c-27.57,0-50-22.43-50-50s22.43-50,50-50h142.185c3.206,0,6.217-1.537,8.098-4.133l33.472-46.203h29.264L272.76,312H178   c-27.57,0-50,22.43-50,50v20C128,385.506,128.61,388.87,129.72,392z M158,392c-5.514,0-10-4.486-10-10v-20   c0-16.542,13.458-30,30-30h92.27l7.718,20H218c-5.51,0-10,4.49-10,10c0,5.51,4.49,10,10,10h67.705l7.718,20H158z M128,492   c-5.514,0-10-4.486-10-10s4.486-10,10-10h137.017c3.263,0,6.32-1.592,8.191-4.264c1.872-2.673,2.321-6.09,1.206-9.156L257.46,412   h21.46L298,463.784V482c0,5.514-4.486,10-10,10H128z M309.364,377.758l-20.687-53.607l81.509-118.797l21.739,14.09L309.364,377.758   z M454,98.563l-52.895,102.997l-28.214-18.287c-1.485-0.963-3.133-1.474-4.785-1.58c-0.034-0.017-0.069-0.029-0.106-0.029h-32.778   l54.904-75.787c0.154-0.212,0.299-0.431,0.436-0.655c0.054-0.086,0.115-0.188,0.16-0.267c0.884-1.524,1.35-3.255,1.35-5.017V50.964   c0-17.074,13.89-30.964,30.964-30.964S454,33.89,454,50.964V98.563z", fill: "currentColor" }),
            React.createElement("path", { d: "M178,352c-5.51,0-10,4.49-10,10c0,5.51,4.49,10,10,10c5.51,0,10-4.49,10-10C188,356.49,183.51,352,178,352z", fill: "currentColor" })))),
    [FareFamilyOptionsType.Meal]: (React.createElement("svg", { width: "22", height: "22", viewBox: "0 0 22 22", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M17.1732 0.5V4.31818H21.9459L20.3709 20.0968C20.2754 20.8795 19.6168 21.5 18.815 21.5H17.1923L17.1732 13.8636C17.1732 9.82591 14.2141 7.33455 10.7109 6.52318L10.4913 4.31818H15.2641V0.5H17.1732ZM1.91001 21.4905C1.37547 21.4905 0.945923 21.0609 0.945923 20.5264V19.5909H15.2737V20.5264C15.2737 21.0609 14.8441 21.4905 14.3096 21.4905H1.91001ZM8.10501 8.12681C4.52547 8.12681 0.945923 10.0455 0.945923 13.8636H15.2641C15.2641 10.0455 11.6846 8.12681 8.10501 8.12681ZM15.2641 15.7727H0.945923V17.6818H15.2641V15.7727Z", fill: "currentColor" }))),
    [FareFamilyOptionsType.Refundable]: (React.createElement("svg", { width: "33", height: "32", viewBox: "0 0 33 32", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M9.74506 3.58984L4.33301 8.92318L9.74506 14.2565V10.2565H17.863C21.5992 10.2565 24.6281 13.2413 24.6281 16.9232C24.6281 20.6051 21.5992 23.5898 17.863 23.5898H9.74502V26.2565H17.863C23.0937 26.2565 27.3341 22.0778 27.3341 16.9232C27.3341 11.7685 23.0937 7.58984 17.863 7.58984H9.74506V3.58984Z", fill: "currentColor" }))),
    [FareFamilyOptionsType.Exchangeable]: (React.createElement("svg", { width: "33", height: "32", viewBox: "0 0 33 32", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M13.5677 4.9231H11.2483V7.03292H9.3252H8.17135C7.15175 7.03292 6.3252 7.85947 6.3252 8.87907V10.0329V13.8901V25.7582C6.3252 26.7778 7.15175 27.6043 8.17135 27.6043H25.3541C26.3737 27.6043 27.2003 26.7778 27.2003 25.7582V13.8901V10.0329V8.87907C27.2003 7.85947 26.3737 7.03292 25.3541 7.03292H24.2003H22.8457V4.9231H20.5262V7.03292H13.5677V4.9231ZM25.9695 13.8901H7.55596V25.7582C7.55596 26.0981 7.83148 26.3736 8.17135 26.3736H25.3541C25.694 26.3736 25.9695 26.0981 25.9695 25.7582V13.8901ZM14.7849 19.3292H14.0646V20.4498H14.8075C15.1005 20.4498 15.33 20.5182 15.4961 20.6551C15.6622 20.792 15.7453 21.0242 15.7453 21.3516C15.7453 21.5807 15.6653 21.7697 15.5052 21.9185C15.3451 22.0644 15.1307 22.1373 14.8619 22.1373C14.6233 22.1373 14.4225 22.0644 14.2594 21.9185C14.0963 21.7727 14.0148 21.6001 14.0148 21.4007H12.479C12.479 21.7667 12.5742 22.0941 12.7644 22.3828C12.9547 22.6685 13.2341 22.8932 13.6025 23.0569C13.974 23.2176 14.3757 23.298 14.8075 23.298C15.5445 23.298 16.1394 23.1239 16.5924 22.7757C17.0455 22.4275 17.272 21.9632 17.272 21.3828C17.272 21.0078 17.1723 20.6938 16.973 20.4408C16.7736 20.1849 16.4988 19.9944 16.1485 19.8694C16.4807 19.7147 16.7329 19.5138 16.905 19.2667C17.0802 19.0167 17.1678 18.7519 17.1678 18.4721C17.1678 17.8947 16.9579 17.4408 16.5381 17.1105C16.1183 16.7801 15.5414 16.615 14.8075 16.615C14.3968 16.615 14.0193 16.6908 13.675 16.8426C13.3337 16.9914 13.0664 17.1998 12.8732 17.4676C12.6799 17.7355 12.5832 18.0405 12.5832 18.3828H14.1099C14.1099 18.2102 14.1839 18.0658 14.3319 17.9498C14.4829 17.8337 14.6596 17.7757 14.8619 17.7757C15.1096 17.7757 15.3013 17.8441 15.4372 17.981C15.5731 18.115 15.6411 18.2891 15.6411 18.5033C15.6411 19.0539 15.3557 19.3292 14.7849 19.3292ZM19.8949 23.2087H21.4261V16.7087H21.2857L18.3909 17.6953V18.8382L19.8949 18.4051V23.2087Z", fill: "currentColor" }))),
    [FareFamilyOptionsType.SportsEquipment]: SportEquipment,
    [FareFamilyOptionsType.OnlineRegistration]: PhoneSuccess
};

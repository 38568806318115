import React from 'react';
import { useTranslation } from 'react-i18next';
import CalendarComponent from '@websky/core/src/Results/components/Results/Calendar/Calendar';
import css from './Calendar.css';
const Calendar = props => {
    const { t } = useTranslation('Results');
    return (React.createElement(CalendarComponent, Object.assign({}, props, { renderTitle: ({ legNumber, departureCity, arrivalCity }) => (React.createElement("div", { className: css.title },
            React.createElement("div", { className: css.number }, legNumber),
            React.createElement("div", { className: css.text },
                React.createElement("span", null, t('Select flight')),
                "\u00A0",
                React.createElement("span", { className: css.cities },
                    departureCity,
                    " \u2013 ",
                    arrivalCity)))) })));
};
export default Calendar;

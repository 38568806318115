import { __awaiter } from "tslib";
import * as React from 'react';
import { useState } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../theme';
import { WarningRounded } from '../../Icons';
import PriceInMiles from '../../PriceInMiles/PriceInMiles';
import Button from '../../Button';
import ModalConfirm from '../../ModalConfirm/components/ModalConfirm';
const FfpInfoNotice = ({ className, ffpInfo, onRemoveCard, onPayByMoney }) => {
    const { FfpInfoNotice: css } = useTheme('FfpInfoNotice');
    const { t } = useTranslation('Results');
    const [isOpen, setIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const onOpenModal = () => setIsOpen(true);
    const onCloseModal = () => setIsOpen(false);
    const onRemoveCardHandler = () => __awaiter(void 0, void 0, void 0, function* () {
        setIsLoading(true);
        try {
            yield onRemoveCard();
        }
        catch (e) {
            console.log(e);
        }
        finally {
            setIsLoading(false);
        }
    });
    return (React.createElement("div", { className: cn(className, css.wrapper) },
        React.createElement("div", { className: css.icon }, WarningRounded),
        React.createElement("div", { className: css.text },
            React.createElement("span", { className: css.accent },
                t('Redeem miles'),
                ":"),
            " ",
            t('Card'),
            " ",
            ffpInfo.cardNumber,
            ",",
            ' ',
            React.createElement("span", { className: css.status }, ffpInfo.currentLevel),
            ",",
            React.createElement(PriceInMiles, { amount: ffpInfo.numberOfMiles })),
        React.createElement("div", { className: css.actions },
            React.createElement(Button, { className: css.button, onClick: onOpenModal, variant: 'text' }, t('Change card')),
            React.createElement(Button, { className: css.button, onClick: onPayByMoney, variant: 'text' }, t('Pay by money'))),
        React.createElement(ModalConfirm, { isOpen: isOpen, onClose: onCloseModal, title: t('You sure?'), confirmBtn: t('Yes'), onConfirm: onRemoveCardHandler, cancelBtn: t('No'), onCancel: isLoading ? null : onCloseModal }, t('Delete card from your account?'))));
};
export default FfpInfoNotice;
